import { axiosPost, axiosGet, axiosDelete, axiosPatch } from "./axiosFunctions";
import { getTokenOrLogout } from "./common";
import { getTempState, getAuthState } from "../../assets/scripts/login-util";

const updateSubAgentUrl                             = '/api/subAgency/updateSubAgent';
const getSubAgentCategoryByAgentIDUrl               = (agentId) => `/api/subAgency/getSubAgentCategoryByAgentID/${agentId}`;
const deleteSubAgentByIDUrl                         = (subAgentID) => `/api/subAgency/deleteSubAgent/${subAgentID}`;
const getSubAgentPolicyHistoryUrl                   = (groupId) => `/api/subAgency/getSubAgentPolicyHistory/${groupId}`;
const getSubAgentPolicyTimelineUrl                  = (policyId) => `/api/subAgency/getSubAgentPolicyTimeline/${policyId}`;
const deleteSubAgentPolicyDocumentUrl               = '/api/subAgency/deleteSubAgentPolicyDocument';
const renewSubAgentPolicyUrl                        = '/api/subAgency/renewSubAgentPolicy';
const addSubAgentPolicyUrl                          = '/api/subAgency/addSubAgentPolicy';
const postSubAgentMarinePolicyUrl                   = '/api/subAgency/postSubAgentMarinePolicy';
const postSubAgentMotorPolicyUrl                    = '/api/subAgency/postSubAgentMotorPolicy';
const addSubAgentPolicyDocumentsUrl                 = '/api/subAgency/addSubAgentPolicyDocuments';
const updateSubAgentPolicyDocumentUrl               = '/api/subAgency/updateSubAgentPolicyDocument';
const getSubAgentPolicyStatsUrl                     = '/api/subAgency/getSubAgentPolicyStats';
const getSubClientsByGroupIDUrl                     = (groupId) => `/api/subAgency/getSubClientsByGroupID/${groupId}`;
const getSubAgentPolicyDocumentsUrl                 = (policyId) => `/api/subAgency/getSubAgentPolicyDocuments/${policyId}`;
const getSubAgentMotorPolicyUrl                     = (policyId) => `/api/subAgency/getSubAgentMotorPolicy/${policyId}`;
const getSubAgentMarinePolicyUrl                    = (policyId) => `/api/subAgency/getSubAgentMarinePolicy/${policyId}`;
const getSubAgentPolicyStatsBySubAgentIDUrl         = (subAgentID) => `/api/subAgency/getSubAgentPolicyStatsBySubAgentID/${subAgentID}`;
const getSubAgentPolicyStatsByPrimaryClientIDUrl    = (primaryClientID) => `/api/subAgency/getSubAgentPolicyStatsByPrimaryClientID/${primaryClientID}`;
const getPayoutCategoryUrl                          = '/api/subAgency/getPayoutCategory';
const addPayoutCategoryUrl                          = '/api/subAgency/addPayoutCategory';
const addPayoutPlanUrl                              = '/api/subAgency/addPayoutPlan';
const updatePayoutPlanUrl                           = '/api/subAgency/updatePayoutPlan';
const getPayoutPlanUrl                              = '/api/subAgency/getPayoutPlan';
const getIncentiveTargetUrl                         = (payoutPlanId) => `/api/subAgency/getPayoutPlanIncentiveTargetByPayoutPlanID/${payoutPlanId}`;
const getAllIncentiveTargetUrl                      = '/api/subAgency/getAllPayoutPlanIncentiveTarget';
const deletePayoutPlanUrl                           = (payoutPlanId) => `/api/subAgency/deletePayoutPlan/${payoutPlanId}`;
const getSubAgentsByAgentCompanyUrl                 = '/api/subAgency/getSubAgentsByAgentCompanyID';
const getSubAgentsByAgentIDUrl                      = '/api/subAgency/getSubAgentsByAgentID';
const addSubAgentUrl                                = '/api/subAgency/addSubAgent';
const addSubClientUrl                               = (groupId, email) => `/api/subAgency/addSubClient/${groupId}/${email}`;
const verifySubClientEmailUrl                       = (subAgentID, type) => `/api/subAgency/verifySubClientEmail/${subAgentID}/${type}`;
const postSubClientsUrl                             = '/api/subAgency/postSubClients';
const getSubClientsUrl                              = '/api/subAgency/getSubClients';
const deactivateSubClientUrl                        = (clientId) => `/api/subAgency/deactivateSubClient/${clientId}`;
const updateSubClientUrl                            = (clientId, email) => `/api/subAgency/updateSubClient/${clientId}/${email}`;
const updateSubClientWithoutEmailUrl                = (clientId) => `/api/subAgency/updateSubClient/${clientId}`;
const deactivateSubClientGroupUrl                   = (groupId) => `/api/subAgency/deactivateSubClientGroup/${groupId}`;
const updateSubClientCompanyUrl                     = (groupId) => `/api/subAgency/updateSubClientCompany/${groupId}`;
const getSubClientBySubAgentIDUrl                   = (agentId) => `/api/subAgency/getSubClientBySubAgentID/${agentId}`;
const getSubClientsByAgentIDUrl                     = '/api/subAgency/getSubClientsByAgentID';
const updateSubAgentPolicyUrl                       = '/api/subAgency/updateSubAgentPolicy';
const getAgentVisitedUrl                            = (page) => `/api/subAgency/getAgentVisited/${page}`;
const addAgentVisitedUrl                            = (page) => `/api/subAgency/addAgentVisited/${page}`;
const getBusinessReportsUrl                         = '/api/subAgency/getBusinessReports';
const getBusinessReportsForMotorUrl                 = '/api/subAgency/getBusinessReportsForMotor';
const getBusinessReportsByAgentIDUrl                = '/api/subAgency/getBusinessReportsByAgentID';
const verifySubAgentCodeByAgentCompanyIDUrl         = '/api/subAgency/verifySubAgentCodeByAgentCompanyID';
const deactivateSubAgentPolicyUrl                   = '/api/subAgency/deactivateSubAgentPolicy';
const verifySubAgentEmailUrl                        = '/api/subAgency/verifySubAgentEmail';

export const updateSubAgent = async (subAgent) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);
    const response = await axiosPost(
        updateSubAgentUrl,
        { subAgent },
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getSubAgentCategoryByAgentID = async (agentId) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000); 
    const response = await axiosGet(
        getSubAgentCategoryByAgentIDUrl(agentId),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const deleteSubAgentByID = async (subAgentID) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);  
    const response = await axiosDelete(
        deleteSubAgentByIDUrl(subAgentID),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getSubAgentPolicyHistory = async (groupId) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);
    const response = await axiosPost(
        getSubAgentPolicyHistoryUrl(groupId),
        {},
        { headers: { auth_token: `bearer ${token}` }
    });
    return response;
};

export const getSubAgentPolicyTimeline = async (policyId) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);
    const response = await axiosGet(
        getSubAgentPolicyTimelineUrl(policyId),
        { headers: { auth_token: `bearer ${token}` }
      
    });
    return response;
};

export const deleteSubAgentPolicyDocument = async (policyDocument) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);
    const response = await axiosPost(
        deleteSubAgentPolicyDocumentUrl,
        { policyDocument : policyDocument },
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const renewSubAgentPolicy = async (policy) => {
    const token = getTokenOrLogout();
        if (!token) setTimeout(()=> { return }, 2000);
  
    const response = await axiosPost(
        renewSubAgentPolicyUrl,
        { policy : policy },
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};
export const getSubAgentMotorPolicy = async (policyId) => {
    const token = getTokenOrLogout();
      if (!token) setTimeout(()=> { return }, 2000);
  
    const response = await axiosGet(
        getSubAgentMotorPolicyUrl(policyId),
      { headers: { auth_token: `bearer ${token}` }
      
    });
    return response;
};
  
export const getSubAgentMarinePolicy = async (policyId) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);

    const response = await axiosGet(
        getSubAgentMarinePolicyUrl(policyId),
    { headers: { auth_token: `bearer ${token}` }
    
    });
    return response;
};
  
export const getSubAgentPolicyStatsBySubAgentID = async (subAgentID, FromDate, ToDate) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);
  
    const response = await axiosPost(
        getSubAgentPolicyStatsBySubAgentIDUrl(subAgentID),
        { FromDate, ToDate },
        { headers: { auth_token: `bearer ${token}` }
      
    });
    return response;
};

export const getSubAgentPolicyStatsByPrimaryClientID = async (primaryClientID, FromDate, ToDate) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);
  
    const response = await axiosPost(
        getSubAgentPolicyStatsByPrimaryClientIDUrl(primaryClientID),
        { FromDate, ToDate },
        { headers: { auth_token: `bearer ${token}` }
      
    });
    return response;
};

export const updateSubClientCompany = async (groupId, companyName) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);

    const response = await axiosPatch(
      updateSubClientCompanyUrl(groupId),
        { companyName },
        { headers: { auth_token: `bearer ${token}` } } 
    );
    return response;
};

export const deactivateSubClientGroup = async (groupId) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);

    const response = await axiosPatch(
        deactivateSubClientGroupUrl(groupId),
        null,
        { headers: { auth_token: `bearer ${token}` } }   
    );
    return response;
};

export const updateSubClient = async (client) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);

    const response = await axiosPatch(
        client.Email ? (client.ID, client.Email) : updateSubClientWithoutEmailUrl(client.ID),
        { ...client },
        { headers: { auth_token: `bearer ${token}` } }   
    );
    return response;
};

export const deactivateSubClient = async (clientId,SubAgentID) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);

    const response = await axiosPatch(
        deactivateSubClientUrl(clientId),
        {SubAgentID},
        { headers: { auth_token: `bearer ${token}` } }   
    );
    return response;
};

export const getSubClientsByAgentID = async () => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);
    
    const response = await axiosGet(
        getSubClientsByAgentIDUrl,
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getSubClients = async () => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);
    
    const response = await axiosGet(
        getSubClientsUrl,
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getPayoutCategory = async () => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);  
    
    const response = await axiosGet(getPayoutCategoryUrl, {
        headers: { auth_token: `bearer ${token}` },
    });
    return response;
};

export const getPayoutPlan = async (from) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);  
  
    const response = await axiosPost(getPayoutPlanUrl, { from }, {
        headers: { auth_token: `bearer ${token}` },
    });
    return response;
};  

export const getAllIncentiveTarget = async () => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);  
  
    const response = await axiosPost(getAllIncentiveTargetUrl, {}, {
        headers: { auth_token: `bearer ${token}` },
    });
    return response;
};  

export const getPayoutPlanIncentiveTargetByPayoutPlanID = async (payoutPlanId) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);  
  
    const response = await axiosPost(getIncentiveTargetUrl(payoutPlanId), { }, {
        headers: { auth_token: `bearer ${token}` },
    });
    return response;
};

export const getSubAgentsByAgentCompany = async () => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);  
    
    const response = await axiosGet(getSubAgentsByAgentCompanyUrl, {
        headers: { auth_token: `bearer ${token}` },
    });
    return response;
};

export const getSubAgentsByAgentID = async () => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);  
    
    const response = await axiosGet(getSubAgentsByAgentIDUrl, {
        headers: { auth_token: `bearer ${token}` },
    });
    return response;
};

export const updatePayoutPlan = async (payout) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);
    const response = await axiosPost(
        updatePayoutPlanUrl,
        { payout },
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const addPayoutCategory = async (Name) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);  
    
    const response = await axiosPost(
        addPayoutCategoryUrl,
        { Name },
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const addPayoutPlan = async (payout) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);
    const response = await axiosPost(
        addPayoutPlanUrl,
        { payout },
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const deletePayoutPlan = async (payoutPlanId) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);  
    
    const response = await axiosDelete(
        deletePayoutPlanUrl(payoutPlanId),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const addSubAgent = async (subAgent) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);
    const response = await axiosPost(
        addSubAgentUrl,
        { subAgent },
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const verifySubClientEmail = async (emails, type, subAgentID) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);
  
    const response = await axiosGet(
        `${verifySubClientEmailUrl(subAgentID, type)}?emails=["${emails.join('","')}"]`,
        { headers: { auth_token: `bearer ${token}` } }   
    );
    return response;
};

export const addSubClient = async (subClient, groupId) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);
    
    const response = await axiosPost(
       addSubClientUrl(groupId, subClient.Email),
        { ...subClient },
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postSubClients = async (subClients, type , subAgentID) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);
    
    const response = await axiosPost(
        postSubClientsUrl,
        { subClients , type , subAgentID },
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getSubClientBySubAgentID = async (agentId) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);
    
    const response = await axiosGet(
        getSubClientBySubAgentIDUrl(agentId),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const addSubAgentPolicy = async (policy) => {
    const token = getTokenOrLogout();
        if (!token) setTimeout(()=> { return }, 2000);
  
    const response = await axiosPost(
        addSubAgentPolicyUrl,
        { policy : policy },
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const updateSubAgentPolicy = async (policy) => {
    const token = getTokenOrLogout();
        if (!token) setTimeout(()=> { return }, 2000);
  
    const response = await axiosPost(
        updateSubAgentPolicyUrl,
        { policy : policy },
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};  

export const postSubAgentMotorPolicy = async (policy) => {
    const token = getTokenOrLogout();
      if (!token) setTimeout(()=> { return }, 2000);
  
    const response = await axiosPost(
      postSubAgentMotorPolicyUrl,
      { policy : policy },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
  };
  
export const postSubAgentMarinePolicy = async (policy) => {
    const token = getTokenOrLogout();
        if (!token) setTimeout(()=> { return }, 2000);
    const response = await axiosPost(
        postSubAgentMarinePolicyUrl,
        { policy : policy },
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};
  
export const addSubAgentPolicyDocuments = async (policyDocuments) => {
    const token = getTokenOrLogout();
        if (!token) setTimeout(()=> { return }, 2000);
  
    const response = await axiosPost(
        addSubAgentPolicyDocumentsUrl,
        { policyDocuments : policyDocuments },
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
}; 

export const updateSubAgentPolicyDocument = async (policyDocument) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);
    const response = await axiosPost(
        updateSubAgentPolicyDocumentUrl,
        { policyDocument : policyDocument },
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getSubAgentPolicyStats = async (FromDate, ToDate) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);
    const response = await axiosPost(
        getSubAgentPolicyStatsUrl,
        { FromDate, ToDate },
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getSubClientsByGroupID  = async (groupId) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);
  
    const response = await axiosGet(
        getSubClientsByGroupIDUrl(groupId), 
        { headers: { auth_token: `bearer ${token}` },
    });
    return response;
};

export const getSubAgentPolicyDocuments = async (policyId) => {
    const token = getTokenOrLogout();
        if (!token) setTimeout(()=> { return }, 2000);
  
    const response = await axiosGet(
        getSubAgentPolicyDocumentsUrl(policyId),
        { headers: { auth_token: `bearer ${token}` }
      
    });
    return response;
};

export const getAgentVisited = async (page) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);
    
    const response = await axiosGet(
        getAgentVisitedUrl(page),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const addAgentVisited = async (page) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);
    
    const response = await axiosGet(
        addAgentVisitedUrl(page),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getBusinessReports = async (FromDate, ToDate) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);
    const response = await axiosPost(
        getBusinessReportsUrl,
        { FromDate, ToDate },
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getBusinessReportsForMotor = async (FromDate, ToDate) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);
    const response = await axiosPost(
        getBusinessReportsForMotorUrl,
        { FromDate, ToDate },
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getBusinessReportsByAgentID = async (FromDate, ToDate) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);
    const response = await axiosPost(
        getBusinessReportsByAgentIDUrl,
        { FromDate, ToDate },
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const verifySubAgentCodeByAgentCompanyID = async (subAgent) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);
    const response = await axiosPost(
        verifySubAgentCodeByAgentCompanyIDUrl,
        { subAgent },
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
}

export const deactivateSubAgentPolicy = async (data) => {   
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);  
    
    const response = await axiosPost(deactivateSubAgentPolicyUrl, {data}, {
        headers: { auth_token: `bearer ${token}` },
    });
    return response;
}
  
export const verifySubAgentEmail = async (subAgent) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);
    const response = await axiosPost(
        verifySubAgentEmailUrl,
        { subAgent },
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};