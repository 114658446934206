import moment from 'moment'
import { getInsuranceCompanies, getMotorMake, getMotorModel, getPolicyRegex, getProducts } from '../../components/api/policyAPI'


const getWords          = (str) => str.split(/\s+/).filter(Boolean).filter(f => f !== 'insurance' && f !== 'ltd' && f !== 'ltd.' && f !== 'limited' && f !== 'co' && f !== 'co.' && f !== 'company' && f !== 'of' && f !== 'on' && f !== 'general' && f !== 'the'  && f.length > 1);
const getMatchedWords   = (words1, words2) => words2.filter(word => words1.includes(word));
const getMatchedRatio   = (words1, words2) => +((100 * words1.length) / words2.length).toPrecision(2);
const countOcurrences   = (str, value) => {
    const regExp = new RegExp(value, 'g'); 
    return (str.match(regExp) || []).length;
}

const extractEngineAndChassisNumbers = (text) => {
    // Regular expressions for Engine/Battery Number and Chassis Number
    const engineNoRegex = /(engine\s*number|battery\s*number|engine\s*no|battery\s*no)\.?\s*[:\-]?\s*([\w\d\-]+)/i;
    const chassisNoRegex = /(chassis\s*number|chassis\s*no)\.?\s*[:\-]?\s*([\w\d\-]+)/i;

    const engineMatch = text.match(engineNoRegex);
    const chassisMatch = text.match(chassisNoRegex);
    // console.log(engineMatch)
    const engineNo = engineMatch ? engineMatch[2].trim() : null;
    const chassisNo = chassisMatch ? chassisMatch[2].trim() : null;

    return { engineNo, chassisNo };
};

const getCompanySpeedRatio  = (words1, words2, words3, matchRatio, fullname, text) => {

    const indexList1 = words1.map(word => words2.findIndex(f => f === word));
    const sum1       = indexList1.reduce((total, num) => total + num);
    const avg1       = Math.round(sum1 / indexList1.length);

    const indexList2 = words1.map(word => words3.findIndex(f => f === word));
    const sum2       = indexList2.reduce((total, num) => total + num);
    const avg2       = Math.round(sum2 / indexList2.length);

    const indexList3 = words1.map(word => countOcurrences(text, word));
    const sum3       = indexList3.reduce((total, num) => total + num);
    const avg3       = Math.round(sum3 / indexList3.length);

    const fullNameIncludes = text.includes(fullname);
    const ratio = Math.round(((avg1 + avg2) / 2) - (avg3 * 50) - (fullNameIncludes ? 50 : 0) - (matchRatio == 100 ? 50 : 0));

    return ratio;
}

const getProductSpeedRatio  = (product, fullname, text) => {

    const count             = countOcurrences(text, product);
    const index             = text.indexOf(product);
    const fullNameIncludes  = text.includes(fullname);
    const ratio             = count - (index / fullNameIncludes ? 100 : 0);
    return ratio;
}

const getProductMatchRatio  = (words1, words2, fullname, text) => {

    const indexList1 = words1.map(word => {
        const arr = words2.filter(f => f === word);
        return arr.length;
    });

    const fullNameIncludes = text.includes(fullname);

    const sum1 = indexList1.reduce((total, num) => total + num);
    const avg1 = Math.round(sum1 / indexList1.length);

    const ratio = words1.includes('travel') ? sum1 : avg1 + (fullNameIncludes ? 5 : 0);
    return ratio;
}

const validateDate  = (dateList, indexList, text) => {

    const validList = indexList.map(d => {
        let isValid = false;
        const str = text.substring(d - 100, d + 50).split(/\s+/);
        
        if (str.includes('from') || str.includes('from:') || str.includes('to:') || str.includes('issue') || str.includes('issue:') || str.includes('issued') || str.includes('issued:') || str.includes('issuance'))
            isValid = true;

        return isValid
    })

    return dateList.filter((f, i) => validList[i]).filter((f, i, arr) => i == arr.findIndex(s => s === f));

}

const validateAmount  = (amountList, indexList, text) => {

    const validList = indexList.map((d, i) => {
        let isValid = false;
        let type = '';
        let ind = -1;
        let priority = 5;

        if (text.substring(d - 100, d).match(/net\s+premium/)) {
            type = 'Premium';
            isValid = true;
            ind = [...text.substring(d - 100, d).matchAll(/net\s+premium/g)].map(a => a.index)[0];
            priority = 1;
        }
        if (text.substring(d - 100, d).match(/before\s+gst/)) {           
            if (!isValid){
                type = 'Premium';
                isValid = true;
                ind = [...text.substring(d - 100, d).matchAll(/before\s+gst/g)].map(a => a.index)[0];
                priority = 2;
            }             
        }
        if (text.substring(d - 100, d).match(/a\+b/)) {
            if (isValid) {
                const ind1 = [...text.substring(d - 100, d).matchAll(/a\+b/g)].map(a => a.index)[0];
                if (priority > 1 && ind1 > ind)
                    ind = ind1;
            }else{
                type = 'Premium';
                isValid = true;
                ind = [...text.substring(d - 100, d).matchAll(/a\+b/g)].map(a => a.index)[0];
                priority = 2; 
            }
        }

        if (text.substring(d - 100, d).match(/sum\s+insured/)) {
            if (isValid) {
                const ind1 = [...text.substring(d - 100, d).matchAll(/sum\s+insured/g)].map(a => a.index)[0];
                if (priority > 1 || ind1 > ind){
                    ind = ind1;
                    type = 'SumInsured';
                    priority = 1;
                }           
            }else{
                type = 'SumInsured';
                isValid = true;
                ind = [...text.substring(d - 100, d).matchAll(/sum\s+insured/g)].map(a => a.index)[0];
                priority = 1;           
            }                         
        }

        if (text.substring(d - 100, d).match(/idv/)) {
            if (isValid) {
                const ind1 = [...text.substring(d - 100, d).matchAll(/idv/g)].map(a => a.index)[0];
                if (type == 'SumInsured') {
                    if (ind1 > ind)
                        ind = ind1;
                }else{
                    if (priority > 1 || ind1 > ind) {
                        ind = ind1;
                        type = 'SumInsured';
                        priority = 1;
                    }
                }
            }else{
                type = 'SumInsured';
                isValid = true;
                ind = [...text.substring(d - 100, d).matchAll(/idv/g)].map(a => a.index)[0];
                priority = 1;
            }           
        }
        if (text.substring(d - 100, d).match(/insured\s+declared\s+value/)) {
            if (isValid) {
                const ind1 = [...text.substring(d - 100, d).matchAll(/insured\s+declared\s+value/g)].map(a => a.index)[0];
                if (type == 'SumInsured') {
                    if (ind1 > ind)
                        ind = ind1;
                }else{
                    if (priority > 1 || ind1 > ind) {
                        ind = ind1;
                        type = 'SumInsured';
                        priority = 1;
                    }
                }
            }else{
                type = 'SumInsured';
                isValid = true;
                ind = [...text.substring(d - 100, d).matchAll(/insured\s+declared\s+value/g)].map(a => a.index)[0];
                priority = 1;
            }   
        }

        if (text.substring(d - 100, d).match(/for\s+vehicle/)) {
            if (isValid){
                const ind1 = [...text.substring(d - 100, d).matchAll(/for\s+vehicle/g)].map(a => a.index)[0];
                if (type == 'Premium' && priority > 1 && ind1 > ind) {
                    ind = ind1;
                    type = 'SumInsured';
                    priority = 2;
                }
            }else{
                type = 'SumInsured';
                isValid = true;
                ind = [...text.substring(d - 100, d).matchAll(/for\s+vehicle/g)].map(a => a.index)[0];
                priority = 2;
            }
        }
        if (text.substring(d - 100, d).match(/total\s+value/)) {
            if (isValid) {
                const ind1 = [...text.substring(d - 100, d).matchAll(/total\s+value/g)].map(a => a.index)[0];
                if (type == 'Premium') {
                    if (priority > 1 && ind1 > ind) {
                        ind = ind1;
                        type = 'SumInsured';
                        priority = 2;
                    }
                }else{
                    if (priority > 1 && ind1 > ind)
                        ind = ind1;
                }
            }else{
                type = 'SumInsured';
                isValid = true;
                ind = [...text.substring(d - 100, d).matchAll(/total\s+value/g)].map(a => a.index)[0];
                priority = 2;
            }
        }

        return { isValid, type, ind, priority }
    })

    return validList.map((f, i) => ({
        ...f,
        amount: amountList[i]
    })).filter((f, i, arr) => f.isValid && f.amount.toString().length > 2 && (f.type === 'SumInsured' ? !arr.find(a => a.type === 'Premium' && a.amount > f.amount) : true)).filter((f, i, arr) => i == arr.findIndex(s => s.amount === f.amount));

}


class PolicyExtractor {
    /**
     * @returns {Object} The array containing all the elements containing policy details.
     * @param {String} text All the text in the policy
     * @param {String} name File name
     */
    constructor(text) {
        this.text = text
    }  
    getPolicyDetails = async () => {

        let arr             = this.text.toLowerCase().split(/\s+/);
        let details         = {}
        let allDates        = []
        let revInd          = false

        const insuranceCompanies = await getInsuranceCompanies();
        const products = await getProducts();
        const policyRegex = await getPolicyRegex();
        const words1 = arr.filter(Boolean);

        if (!details.InsuranceCompanyID) {        
            const companyList = [];
            for await (let company of insuranceCompanies){        
                const words2 = getWords(company.FullName.toLowerCase()).filter(f => f !== 'health');
                const matchedWords = getMatchedWords(words1, words2);
                if (matchedWords.length > 0) {
                    const matchRatio = getMatchedRatio(matchedWords, words2);
                    if (matchRatio > 50) {
                        const speedRatio = getCompanySpeedRatio(matchedWords, words1, words2, matchRatio, company.FullName.toLowerCase(), this.text.toLowerCase());
                        const matchScore = matchRatio - speedRatio;
                        companyList.push({ InsuranceCompanyID: company.ID, MatchRatio: matchRatio, SpeedRatio: speedRatio, Score: matchScore, Name: company.FullName });
                    }                   
                }    
            }
            if (companyList && companyList.length > 0) {
                const company = companyList.sort((a, b) => b.MatchRatio - a.MatchRatio).filter((f, i) => i == 0 || i == 1).reduce((prev, current) => (prev.Score > current.Score) ? prev : current);    
                details.InsuranceCompanyID = company.InsuranceCompanyID;
                if (company.Name.toLowerCase().match(/go\sdigit/)) revInd = true;
                const regex = policyRegex.filter(f => f.InsuranceCompanyID == company.InsuranceCompanyID);
   
                if (regex && regex.length > 0) {
                    for (let r of regex) {              
                        const reg = new RegExp(`\\s${r.Regex}\\s`);                
                        const number = this.text.match(reg);            

                        if (number) {
                            if (details.PolicyNo) {
                                let currentLetters = 0;
                                let prevLetters = 0;
                                for (let n of number.toString()){
                                    if ((/[a-zA-Z]/).test(n))
                                        currentLetters = currentLetters + 1;
                                }
                                for (let n of details.PolicyNo){
                                    if ((/[a-zA-Z]/).test(n))
                                        prevLetters = prevLetters + 1;
                                }

                                const currentIndex      = this.text.indexOf(number);
                                const prevIndex         = this.text.indexOf(details.PolicyNo);
                                const prevStingIndex    = this.text.toLowerCase().indexOf('previous policy');
                                const curentDiff        = Math.abs(prevStingIndex - currentIndex);
                                const prevDiff          = Math.abs(prevStingIndex - prevIndex); 
                                
                                if (curentDiff > 0 && prevDiff > 0 && prevStingIndex > 0 && curentDiff > prevDiff){
                                    details.PolicyNo = number.toString();
                                }else if(curentDiff == prevDiff || prevStingIndex < 1){
                                    if (currentLetters > prevLetters) {
                                        details.PolicyNo = number.toString();
                                    }else if ((details.PolicyNo.length < number.toString().length) && (currentLetters == prevLetters)) {
                                        details.PolicyNo = number.toString();
                                    }
                                }
                                    
                            }else{
                                details.PolicyNo = number.toString();
                            }
                            
                        }
                    }
                }           
            }    
        }

        if (!details.ProductID) {
            const productList = [];
            for await (let product of products){        
                let words2 = '';

                if (product.Name === 'Health'|| product.Name === 'Office' || product.Name === 'House' || product.Name === 'Money' || product.Name === 'Burglary' || product.Name === 'WC' || product.Name === 'Critical Illness')
                    words2 = product.FullName.toLowerCase();
                else
                    words2 = product.Name.toLowerCase();
        
                const reg = new RegExp(`\\s${words2}\\s`);   
                const isMatched = this.text.toLocaleLowerCase().match(reg);

                if (isMatched) {
                    const matchScore = getProductSpeedRatio(words2, product.FullName.toLowerCase(), this.text.toLowerCase());
                    productList.push({ ProductID: product.ID, Score: matchScore, Name: product.Name, ExtraFields: product.ExtraFields });                  
                }  
            }

            if (productList.length == 0) {
                for await (let product of products){        
                    const words3 = getWords(product.FullName.toLowerCase());
                    const matchedWords1 = getMatchedWords(words1, words3);
                    if (matchedWords1.length > 0) {
                        const matchScore = getProductMatchRatio(matchedWords1, words1, product.FullName.toLowerCase(), this.text.toLowerCase());
                        if (matchScore > 2) {
                            productList.push({ ProductID: product.ID, Score: matchScore, Name: product.Name, ExtraFields: product.ExtraFields });   
                        }           
                    }
                }            
            }
            if (productList && productList.length > 0) {
                const product = productList.reduce((prev, current) => (current.Score >= prev.Score) ? current : prev);    
                details.ProductID = product.ProductID;
                
                if (product.ExtraFields === 'MotorPolicy') {

                    const { engineNo, chassisNo } = extractEngineAndChassisNumbers(this.text.toLowerCase());

                    details.EngineNo = ((engineNo != '' || !engineNo) ? engineNo.toUpperCase() : '');
                    details.ChassisNo = ((chassisNo != '' || !chassisNo) ? chassisNo.toUpperCase() : '');

                    let registrationNo = this.text.match(/[A-Z]{2}[0-9]{2}[A-Z]{1,2}[0-9]{4}/);
                    if (!registrationNo)
                        registrationNo = this.text.match(/[A-Z]{2}-[0-9]{2}-[A-Z]{1,2}-[0-9]{4}/);       
                    if (!registrationNo)
                        registrationNo = this.text.match(/[A-Z]{2}\s+[0-9]{2}\s+[A-Z]{1,2}\s+[0-9]{4}/);
                    if (registrationNo)
                        details.RegistrationNo = registrationNo.toString().replace(/\s+/g, '').replace(/-/g, '');

                    let type = this.text.toLowerCase().match(/\s+diesel\s+/);
                    if (type)
                        details.Type = 'Diesel';

                    const makes = await getMotorMake();
                    const makeList = [];
                    for await (let make of makes){ 
                        if (make.Name.length < 4)
                            make.Name = '\\s' + make.Name + '\\s';    
                        const count = countOcurrences(this.text.toLowerCase().replace(/\s\s+/g, ' '), make.Name.toLowerCase());
                        if (count > 0) makeList.push({...make, Count: count});                   
                    } 
                    if (makeList.length > 0) {
                        const make = makeList.reduce((prev, current) => (current.Count >= prev.Count && (current.Name !== 'Mahindra' && current.Name !== 'Indian')) ? current : prev); 
                        details.Make = make.Name;

                        const models = await getMotorModel(make.ID);
                        const modelList = [];
                        for await (let model of models){      
                            let count = countOcurrences(this.text.toLowerCase().replace(/\s\s+/g, ' '), model.Name.toLowerCase());
                            if (count == 0) {
                                count = countOcurrences(this.text.toLowerCase().replace(/\s\s+/g, ' '), model.Name.toLowerCase().replace(/-/g, ' '));
                            }
                            if (count > 0) modelList.push({...model, Count: count});                   
                        } 

                        if (modelList.length > 0) {
                            const model = modelList.reduce((prev, current) => (current.Count >= prev.Count && current.Name !== 'GO') ? current : prev); 
                            details.Model = model.Name;
                        }
                    }

                }            
            }          
        }

        if (!details.Premium) {
            let amountCheck = this.text.toLowerCase().match(/(?:\s\d+)((\d{1,3})*([\,\ ]\d{2,3})*)(\.\d+)?/g);
            let amountIndexCheck = [...this.text.toLowerCase().matchAll(/(?:\s\d+)((\d{1,3})*([\,\ ]\d{2,3})*)(\.\d+)?/g)].map(a => a.index);
            if (amountCheck && amountCheck.length > 0) {
                const amounts = validateAmount(amountCheck.map(a => parseInt(a.replace(/,/g, ''))), amountIndexCheck, this.text.toLowerCase());

                if (amounts && amounts.length > 0) {
                    const sumInsuredList =  amounts.filter(f => f.type === 'SumInsured' && (details.RegistrationNo ? f.amount != 1500000 : true)).sort((a, b) => a.priority - b.priority || b.amount - a.amount || b.ind - a.ind);
                    const premiumList =  amounts.filter(f => f.type === 'Premium').sort((a, b) => a.priority - b.priority || (revInd ? a.ind - b.ind : b.ind - a.ind));
                    if (sumInsuredList && sumInsuredList.length > 0) {
                        details.SumInsured = sumInsuredList[0].amount;
                    }
                    if (premiumList && premiumList.length > 0) {
                        details.Premium = premiumList[0].amount;
                    }

                }
            }
            
        }

        let dateCheck = this.text.toLowerCase().match(/[0-9]{2}\/[0-9]{2}\/[0-9]{4}/g);
        let indexCheck = [...this.text.toLowerCase().matchAll(/[0-9]{2}\/[0-9]{2}\/[0-9]{4}/g)].map(a => a.index);
        if (dateCheck && dateCheck.length > 0) {
            const dates = validateDate(dateCheck, indexCheck, this.text.toLowerCase());
            if (dates && dates.length > 0) {
                try {
                    dates.map((d) => {
                        const f = moment(d, 'DD-MM-YYYY');
                        if (f.isValid()) {
                            const t = f.format('YYYY-MM-DD');
                            if (!allDates.includes(t)) {
                                allDates.push(t);
                            }
                        }
                        
                    })              
                } catch (e) { }       
            }          
        }
        dateCheck   = [];
        indexCheck  = [];
        dateCheck   = this.text.toLowerCase().match(/[0-9]{2}-[a-z]{3}-[0-9]{2}\s/g);
        indexCheck  = [...this.text.toLowerCase().matchAll(/[0-9]{2}-[a-z]{3}-[0-9]{2}\s/g)].map(a => a.index);
        if (dateCheck && dateCheck.length > 0) {
            const dates = validateDate(dateCheck, indexCheck, this.text.toLowerCase());
            if (dates && dates.length > 0) {
                try {
                    dates.map((d) => {
                        const f = moment(d, 'DD-MMM-YYYY');
                        if (f.isValid()) {
                            const t = f.format('YYYY-MM-DD');
                            if (!allDates.includes(t)) {
                                allDates.push(t);
                            }
                        }              
                    })              
                } catch (e) { }       
            } 
        }
        dateCheck   = [];
        indexCheck  = [];
        dateCheck   = this.text.toLowerCase().match(/[0-9]{2}-[a-z]{3}-[0-9]{4}/g);
        indexCheck  = [...this.text.toLowerCase().matchAll(/[0-9]{2}-[a-z]{3}-[0-9]{4}/g)].map(a => a.index);
        if (dateCheck && dateCheck.length > 0) {
            const dates = validateDate(dateCheck, indexCheck, this.text.toLowerCase());
            if (dates && dates.length > 0) {
                try {
                    dates.map((d) => {
                        const f = moment(d, 'DD-MMM-YYYY');
                        if (f.isValid()) {
                            const t = f.format('YYYY-MM-DD');
                            if (!allDates.includes(t)) {
                                allDates.push(t);
                            }
                        } 
                    })              
                } catch (e) { }       
            } 
        }
        dateCheck   = [];
        indexCheck  = [];
        dateCheck   = this.text.toLowerCase().match(/[0-9]{2}\s[a-z]{3}\s[0-9]{4}/g);
        indexCheck  = [...this.text.toLowerCase().matchAll(/[0-9]{2}\s[a-z]{3}\s[0-9]{4}/g)].map(a => a.index);
        if (dateCheck && dateCheck.length > 0) {
            const dates = validateDate(dateCheck, indexCheck, this.text.toLowerCase());
            if (dates && dates.length > 0) {
                try {
                    dates.map((d) => {
                        const f = moment(d, 'DD MMM YYYY');
                        if (f.isValid()) {
                            const t = f.format('YYYY-MM-DD');
                            if (!allDates.includes(t)) {
                                allDates.push(t);
                            }
                        } 
                    })              
                } catch (e) { }       
            } 
        }

        if (allDates.length > 0) {
            let sortedDates = allDates.sort((a, b) => moment(a, 'YYYY-MM-DD').diff(moment(b, 'YYYY-MM-DD')));
            details.IssueDate = sortedDates[0];
            details.FromDate = sortedDates.length > 2 ? sortedDates[1] : sortedDates[0]
            details.ToDate = sortedDates[sortedDates.length - 1];

            const diff = moment(details.FromDate).diff(details.IssueDate, 'months');
            if (diff > 3) details.FromDate = details.IssueDate;

            const days = moment(details.ToDate).diff(details.FromDate, 'days');

            if (((days % 364) != 0) && ((days % 365) != 0)) {
                const days1 = moment(details.ToDate).diff(details.IssueDate, 'days');
                if (((days1 % 364) == 0) || ((days1 % 365) == 0)) {
                    const d = details.FromDate;
                    details.FromDate = details.IssueDate;
                    details.IssueDate = d;
                }
            }
        }

        return details
    }

}

export default PolicyExtractor


