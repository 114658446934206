import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import 'jquery-form';
import 'jquery-validation';
import '../../assets/scripts/bootstrap-modal';
import '../../assets/scripts/jquery-validation.init';
import * as helper from '../global/helper';
import CustomEditor from '../global/editor';
import AddQuickText from '../global/quickText';
import swal from 'sweetalert2';
import Select from 'react-select';
import Dropzone from '../global/dropzone';
import EmailPolicy from './emailPolicy';
import { 
  getInsuranceCompanies,  
  postMotorPolicy,
  postMarinePolicy,
  addPolicyDocuments, 
  renewPolicy,
  getMotorPolicy,
  getMarinePolicy,
  getInsuranceCompaniesByAgentID} from '../api/policyAPI';
import { uploadFile, uploadFileList } from '../api/fileAPI';
import AddCompany from './addCompany';
import AddDocument from './addDocument';
import { extractData } from './helperFunctions';
import { addSubAgentPolicyDocuments, getSubAgentMarinePolicy, getSubAgentMotorPolicy, postSubAgentMarinePolicy, postSubAgentMotorPolicy, renewSubAgentPolicy } from '../api/subAgencyAPI';
import { getAuthState } from '../../assets/scripts/login-util';

const RenewPolicy = (props) => {

    const [btnLoading,          setBtnLoading]          = useState(false);
    const [policyDetails,       setPolicyDetails]       = useState({'NCB' : 0, 'AddOnPremium' : 0, 'SendReminder' : 'Y'});
    const [file,                setFile]                = useState(null);
    const [insuranceCompanies,  setInsuranceCompanies]  = useState([]);
    const [addedCompanyId,      setAddedCompanyId]      = useState(null);
    const [documents,           setDocuments]           = useState([]);
    const [addDocument,         setAddDocument]         = useState(false);
    const [emailPolicyId,       setEmailPolicyId]       = useState(null);
    const [addQuickText,        setAddQuickText]        = useState(false);
    const [fillQuickText,       setFillQuickText]       = useState('');
    const [emailPolicy,         setEmailPolicy]         = useState(null);
    const [showRenewalBtn,      setShowRenewalBtn]      = useState(false);

    const userDetails = getAuthState();

    useEffect(() => {
      let ignore = false;
  
      $('#renew-policy-modal').modal({
        backdrop: 'static'
      });
  
      $('#renew-policy-modal').on('hidden.bs.modal', function () {
        $('.modal').hasClass('show') && $('body').addClass('modal-open');
        props.onDismissModal();        
        $('#search-policy-modal').removeClass('modal-blur');       
      });  
      $('#search-policy-modal').addClass('modal-blur');
  
      if (!ignore) {     
        fetchExtraData();   
        fetchInsuranceCompanies();
      }
      return () => {
        ignore = true;
      };
    }, []);

    useEffect(() => {
      $('[data-toggle="kt-popover"]').popover();
    })

    useEffect(() => {  
      if (policyDetails.FromDate && policyDetails.ToDate) {
        const diff = moment(policyDetails.ToDate).diff(policyDetails.FromDate, 'days')
        if (diff < 362) {
          setShowRenewalBtn(true)
        } else {
          setShowRenewalBtn(false)
        }
      }
    }, [policyDetails]);
  
    useEffect(() => {       
      if (addedCompanyId)
          fetchInsuranceCompanies();  
    }, [addedCompanyId]);

    useEffect(() => {
      if(props.policyDetails) {
        setPolicyDetails(p => ({...p, ProductID: props.policyDetails.ProductID}))
      }
    }, [props.policyDetails])
    useEffect(() => {       
      if(props.policyDetails){
        setEmailPolicy({ Email: props.policyDetails.SubAgentEmail, FirstName: props.policyDetails.SubAgentName, ID: props.policyDetails.SubAgentID, Type: props.policyDetails.Type})
      }
    }, [props.policyDetails]);
  
    useEffect(() => {
      if (insuranceCompanies.length > 0 && addedCompanyId) { 
        updatePolicyDetails('InsuranceCompanyID', addedCompanyId);
        setAddedCompanyId(null);
      }          
    }, [insuranceCompanies]);
  
    const fetchInsuranceCompanies = async () => {
      try {
        const response = userDetails.user.categoryId != 3 ? await getInsuranceCompanies() : await getInsuranceCompaniesByAgentID();
        setInsuranceCompanies(
          response
          .sort((a, b) =>
            a.Name > b.Name
              ? 1
              : a.Name < b.Name
              ? -1
              : 0
          )
        );
      } catch (err) {
        swal.fire({
          icon                  : 'error',
          titleText             : 'Error!',
          text                  : err.message,
          buttonsStyling        : false,
          confirmButtonClass    : 'btn btn-brand',
        });
        return;
      }
    };

    const fetchExtraData = async () => {
      try {
        if (props.policyDetails.ExtraFields === 'MotorPolicy') {
          const motorPolicy = await getMotorPolicy(props.policyDetails.ID);
          if (motorPolicy) {
            const { RegistrationNo, Make, Model, Type, ODPremium, TPPremium, AddOnPremium, NCB, EngineNo, ChassisNo } = motorPolicy;
            if (props.policyDetails.ProductID == 2 || props.policyDetails.ProductID == 4 || props.policyDetails.ProductID == 21) {
              setPolicyDetails((d) => {
                return { ...d, 
                  RegistrationNo, 
                  Make, 
                  Model, 
                  Type, 
                  EngineNo,
                  ChassisNo,
                  MakeEditable  : Make ? true : false, 
                  ModelEditable : Model ? true : false, 
                  TypeEditable  : Type ? true : false,
                  EngineNoEditable  : EngineNo ? true : false,
                  ChassisNoEditable  : ChassisNo ? true : false,
                  NCB
                };
              });
            } else {
              setPolicyDetails((d) => {
                return { ...d, 
                  RegistrationNo, 
                  Make, 
                  Model, 
                  Type, 
                  EngineNo,
                  ChassisNo,
                  MakeEditable  : Make ? true : false, 
                  ModelEditable : Model ? true : false, 
                  TypeEditable  : Type ? true : false,
                  EngineNoEditable  : EngineNo ? true : false,
                  ChassisNoEditable  : ChassisNo ? true : false,
                  NCB
                };
              });
            }
          };    
        }else if (props.policyDetails.ExtraFields === 'MarinePolicy'){
          const marinePolicy = await getMarinePolicy(props.policyDetails.ID);
          if (marinePolicy) {
            const { DeclarationBasis, PerBottomLimit, PerSendingLimit, IsAutoReminder } = marinePolicy;
            setPolicyDetails((d) => {
              return { ...d, DeclarationBasis, PerBottomLimit, PerSendingLimit, IsAutoReminder };
            });
          };
        }
      } catch (err) {
        swal.fire({
          icon                  : 'error',
          titleText             : 'Error!',
          text                  : err.message,
          buttonsStyling        : false,
          confirmButtonClass    : 'btn btn-brand',
        });
        return;
      }
     
    }
  
    const fileHandler = async (f) => {
      setFile(f);
      if (f.type === 'application/pdf') {
        try {
          swal.fire({
            titleText           : 'Extracting Data',
            text                : 'Please Wait...',
            showConfirmButton   : false,
            onOpen: () => {
                swal.showLoading();
            }
          });
          const data = await extractData(f);
          if (data) {
            if (data.InsuranceCompanyID && !policyDetails.InsuranceCompanyID)
              updatePolicyDetails("InsuranceCompanyID", data.InsuranceCompanyID);
            if (data.PolicyNo && !policyDetails.PolicyNo)
              updatePolicyDetails("PolicyNo", data.PolicyNo);
            if (data.SumInsured && !policyDetails.SumInsured)
              updatePolicyDetails("SumInsured", data.SumInsured);
            if (data.Premium && !policyDetails.Premium)
              updatePolicyDetails("Premium", data.Premium);
            if (data.FromDate && !policyDetails.FromDate)
              updatePolicyDetails("FromDate", data.FromDate);
            if (data.ToDate && !policyDetails.ToDate)
              updatePolicyDetails("ToDate", data.ToDate);
            if (data.IssueDate && !policyDetails.IssueDate)
              updatePolicyDetails("IssueDate", data.IssueDate);            
          }
          await swal.close();
        } catch (err) {
            await swal.close();
            console.error(err);
        }
      }
    }; 
  
    const removeFileHandler = (e) => {
      e.preventDefault();
      setFile(null);
      setPolicyDetails({});
    }
  
    const updatePolicyDetails = (field, value) => {
      setPolicyDetails((d) => {
        return { ...d, [field]: value };
      });
    };
  
    const setEditorData = (html) => {
      updatePolicyDetails("AdditionalData", html) ;
    };
  
    const companyAddHandler = (e) => {
      e.preventDefault();
      $('#renew-policy-modal').addClass('modal-blur');
      $('#add-company-modal').modal('toggle');
    };
  
    const setDocumentHandler = (d) => {
      setDocuments((a) => {
        const newA = [...a];
        newA.push(d);
        return newA;
      });    
    };
  
    const openDocumentHandler = (index) => {
      const document = documents[index];
      const { DocumentFile } = document;
  
      if (document.DocumentType === 'AgentFile') {
  
        const ext       = helper.getFileExt(DocumentFile.name);
        const name      = (document.DocumentName + '.' + ext).replace(/ /g, '_');
        helper.downloadAndOpenFileHandler("AgentFile", DocumentFile.name, name)
   
      }else{       
        helper.openFileHandler(DocumentFile);
      }
      
    };
  
    const removeDocumentHandler = (index) => {
      setDocuments((a) => {
        const nA = a.filter((b, i) => index !== i);
        return nA;
      })
    };
  
    const refetchHandler = (statusId) => {
      if (props.policyDetails.From === 'policyHistory'){
        props.refetchPolicyHistory({
          ...props.policyDetails,
          ID: emailPolicyId,
          PreviousPolicyID        : props.policyDetails.ID,
          InsuranceCompanyID      : policyDetails.ID,
          StatusID                : statusId,
          Status                  : statusId == 2 ? 'Policy Sent' : 'Policy Issued',
          QuotationStatusID       : 0,
          PolicyNo                : policyDetails.PolicyNo,
          BusinessType            : 'Renewal',
          IssueDate               : policyDetails.IssueDate,
          FromDate                : policyDetails.FromDate,
          ToDate                  : policyDetails.ToDate,
          Premium                 : policyDetails.Premium,
          SumInsured              : policyDetails.SumInsured,
          AdditionalData          : policyDetails.AdditionalData
        });
      }     
      else{
        props.refetchPolicies();
      }
        
      $('#renew-policy-modal').modal('hide');   
    };
  
    const renewPolicyHandler = async (e) => {
      e.preventDefault();
  
      var form = $('#renew-policy-form');
      form.validate().destroy();
  
      form.validate({
        rules: {
          ddlInsuranceCompany: {
            required: true,
          },
          policyNo: {
            required: true,
          },
          sumInsured: {
            required: true,
          },
          premium: {
            required: true,
          },
          tpPremium: {
            required: true,
          },
          addOnPremium: {
            required: true,
          },
          fromDate: {
            required: true,
          },
          toDate: {
            required: true,
          },
          issueDate: {
            required: true,
          },
          registrationNo: {
            required: true,
          },
          make: {
            required: true,
          },
          model: {
            required: true,
          },
        },
      });
  
      if (!form.valid()) {
        return;
      }
  
      try {
        setBtnLoading(true);
  
        if (!file) {
          swal.fire({
            icon                  : 'error',
            titleText             : 'Error!',
            text                  : "Please upload a policy",
            buttonsStyling        : false,
            confirmButtonClass    : 'btn btn-brand',
          });
          setBtnLoading(false);
          return;
        }
  
        // const policyFileName = props.from == 'subAgentPolicyStats' ? await uploadFile(file, "SubAgentPolicy") : await uploadFile(file, "Policy");
        const policyFileName = await uploadFile(file, "Policy");

        const policyID = props.from == 'subAgentPolicyStats' ? await renewPolicy({
          PreviousPolicyID      : props.policyDetails.ID,
          InsuranceCompanyID    : policyDetails.InsuranceCompanyID,
          PolicyNo              : policyDetails.PolicyNo,
          IssueDate             : policyDetails.IssueDate,
          FromDate              : policyDetails.FromDate,
          ToDate                : policyDetails.ToDate,
          Premium               : (policyDetails.ProductID == 1 || policyDetails.ProductID == 3 || policyDetails.ProductID == 20) ? parseInt(policyDetails.Premium) + parseInt(policyDetails.AddOnPremium) + parseInt(policyDetails.TPPremium) 
                                : (props.policyDetails.ExtraFields === 'MotorPolicy' && (policyDetails.ProductID != 2 && policyDetails.ProductID != 4 && policyDetails.ProductID != 21)) ? parseInt(policyDetails.Premium) + parseInt(policyDetails.AddOnPremium) : parseInt(policyDetails.Premium),
          SumInsured            : parseInt(policyDetails.SumInsured),
          AdditionalData        : policyDetails.AdditionalData || '',
          FileName              : policyFileName
        }) : await renewPolicy({
              PreviousPolicyID      : props.policyDetails.ID,
              InsuranceCompanyID    : policyDetails.InsuranceCompanyID,
              PolicyNo              : policyDetails.PolicyNo,
              IssueDate             : policyDetails.IssueDate,
              FromDate              : policyDetails.FromDate,
              ToDate                : policyDetails.ToDate,
              Premium               : (policyDetails.ProductID == 1 || policyDetails.ProductID == 3 || policyDetails.ProductID == 20) ? parseInt(policyDetails.Premium) + parseInt(policyDetails.AddOnPremium) + parseInt(policyDetails.TPPremium) 
                                    : (policyDetails.ProductID != 2 && policyDetails.ProductID != 4 && policyDetails.ProductID != 21) ? parseInt(policyDetails.Premium) + parseInt(policyDetails.AddOnPremium) : parseInt(policyDetails.Premium),
              SumInsured            : parseInt(policyDetails.SumInsured),
              AdditionalData        : policyDetails.AdditionalData || '',
              SendReminder          : policyDetails.SendReminder,
              FileName              : policyFileName
            })       
  
        if (props.policyDetails.ExtraFields === 'MotorPolicy') {
          await postMotorPolicy({
            PolicyID          : policyID,
            RegistrationNo    : policyDetails.RegistrationNo,
            EngineNo          : policyDetails.EngineNo,
            ChassisNo          : policyDetails.ChassisNo,
            Make              : policyDetails.Make,
            Model             : policyDetails.Model,
            Type              : policyDetails.Type || 'Petrol',
            ODPremium         : (policyDetails.ProductID == 2 || policyDetails.ProductID == 4 || policyDetails.ProductID == 21) ? 0 : parseInt(policyDetails.Premium),
            TPPremium         : (policyDetails.ProductID == 2 || policyDetails.ProductID == 4 || policyDetails.ProductID == 21) ? parseInt(policyDetails.Premium)
                              : (policyDetails.ProductID == 1 || policyDetails.ProductID == 3 || policyDetails.ProductID == 20) ? parseInt(policyDetails.TPPremium) : 0,
            AddOnPremium      : (policyDetails.ProductID == 2 || policyDetails.ProductID == 4 || policyDetails.ProductID == 21) ? 0 : parseInt(policyDetails.AddOnPremium),
            NCB               : policyDetails.NCB,
          })
        }else if (props.policyDetails.ExtraFields === 'MarinePolicy') {
          await postMarinePolicy({
            PolicyID          : policyID,
            DeclarationBasis  : policyDetails.DeclarationBasis,
            PerBottomLimit    : parseInt(policyDetails.PerBottomLimit),
            PerSendingLimit   : parseInt(policyDetails.PerSendingLimit),
            IsAutoReminder    : policyDetails.IsAutoReminder,
          });
        }
  
        if (documents && documents.length > 0) {
  
          const uploadList = documents.filter(f => f.DocumentType === 'Upload');
          let fileList = [];
  
          if (uploadList.length > 0)
              fileList = await uploadFileList(uploadList.map((d) => d.DocumentFile), "PolicyDocument");
  
          const postDocuments = [];
          documents.forEach(document => {
            postDocuments.push({
              PolicyID        : policyID,
              FileName        : document.DocumentType === 'Upload' ? fileList.find(f => f.originalname === document.DocumentFile.name).filename : document.DocumentFile.name,
              DocumentName    : document.DocumentName,
              DocumentType    : document.DocumentType === 'Upload' ? 'PolicyDocument' : document.DocumentType
            });
          });
          await addPolicyDocuments(postDocuments);    
        }
        setEmailPolicyId(policyID);
        $('#renew-policy-modal').addClass('modal-blur');   
        swal.fire({
          icon              : 'success',
          titleText         : 'Policy Renewed Successfully',
          showConfirmButton : false,
          timer             : 1500,
        });
      } catch (err) {
        swal.fire({
          icon                  : 'error',
          titleText             : 'Error!',
          text                  : err.message,
          buttonsStyling        : false,
          confirmButtonClass    : 'btn btn-brand',
        });
        setBtnLoading(false);
        return;
      }
    };
  
    return(
      <React.Fragment>
        <div
          className="modal fade"
          id="renew-policy-modal"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true">
  
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Renew Policy</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  disabled={btnLoading}>
                </button>
              </div>
              <div className="modal-body">
                <form id="renew-policy-form" className="kt-form" onSubmit={(e) => e.preventDefault()}>
                  <div className='kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded'>
                    <table className='kt-datatable__table table-striped'>
                        <tbody className='kt-datatable__body' style={{ display: 'block' }}>
                            <tr className='kt-datatable__row block-row'>
                                <td className="kt-datatable__cell text-clip" width="35%">
                                    <div className="kt-user-card-v2">
                                        <div className="kt-user-card-v2__pic">
                                          <img src={props.policyDetails.ProductIcon} alt="product"/>
                                        </div>
                                        <div className="kt-user-card-v2__details">
                                            <span className="kt-user-card-v2__name" title={props.policyDetails.PolicyNo}>{props.policyDetails.PolicyNo}</span>
                                            <span className="kt-user-card-v2__desc">
                                                <span title={props.policyDetails.InsuranceCompanyName}>{props.policyDetails.InsuranceCompanyName}</span><br/>
                                                <span title={props.policyDetails.ProductName}>{props.policyDetails.ProductName}</span>
                                            </span>
                                        </div>
                                    </div>
                                </td>
                                <td className="kt-datatable__cell text-clip" title={props.policyDetails.DisplayName}>
                                    <div className="kt-user-card-v2">
                                        <div className="kt-user-card-v2__details">
                                            <span className="kt-user-card-v2__desc">Insured Name:</span>
                                            <span className="kt-user-card-v2__name">{props.policyDetails.DisplayName}</span>
                            
                                        </div>
                                    </div>
                                </td>
                                <td className="kt-datatable__cell text-clip" title={moment(props.policyDetails.ToDate).format('DD-MMM-YYYY')}>
                                        <div className="kt-user-card-v2">
                                        <div className="kt-user-card-v2__details">
                                            <span className="kt-user-card-v2__desc">End Date:</span>
                                            <span className="kt-user-card-v2__name">{moment(props.policyDetails.ToDate).format('DD/MM/YYYY')}</span>
                            
                                        </div>
                                    </div>
                                </td>
                                <td className="kt-datatable__cell text-clip" title={props.policyDetails.Status}>
                                    <div className={`policy-status block-badge mr-1 badge badge-${props.policyDetails.Status === 'Renewal Due' ? 'secondary' : (props.policyDetails.Status === 'Expired' ? 'label-danger' : 'warning')}`}>
                                        <span>{props.policyDetails.Status}</span>
                                    </div>
                                </td> 
                            </tr>                                                             
                        </tbody>
                    </table>
                  </div>
                  {file ? (
                    <React.Fragment>
                      <div className="row">
                          <div className="col-12">
                              <div className="kt-notification margin-b30" style={{backgroundColor: '#f7f8fa'}}>
                                  <a className="kt-notification__item">
                                      <div className="kt-notification__item-icon margin-r10" onClick={() => helper.openFileHandler(file)}>
                                        <img height="30px" width="30px" src={helper.getFileLogo(file.name)} />
                                      </div>
                                      <div className="kt-notification__item-details" onClick={() => helper.openFileHandler(file)}>
                                          <div className="kt-notification__item-title kt-font-bold">
                                              {file.name}
                                          </div>
                                      </div>
                                      <button 
                                          type="button"                    
                                          onClick={removeFileHandler}
                                          className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10">
                                          <i className="flaticon2-rubbish-bin-delete-button"></i>
                                      </button>
                                  </a>
                              </div>
                          </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="row">
                            <div className="col-9">
                              <div className="form-group">
                                <label htmlFor="ddlInsuranceCompany">Insurance Company</label>       
                                <Select
                                  value={policyDetails && policyDetails.InsuranceCompanyID && {label: insuranceCompanies.find(c => c.ID == policyDetails.InsuranceCompanyID).Name, value: policyDetails.InsuranceCompanyID}}
                                  onChange={(event) => updatePolicyDetails('InsuranceCompanyID', event.value)}
                                  name="ddlInsuranceCompany"
                                  id="ddlInsuranceCompany"
                                  placeholder="Select Company"
                                  options={insuranceCompanies.map(c => ({
                                    label: c.Name,
                                    value: c.ID
                                  }))}
                                />
                              </div>
                            </div>
                            <div className="col-3">
                              <button
                                id="btnNewCompany"
                                className="btn btn-label-brand btn-block margin-0 margin-t24"
                                data-toggle="kt-popover"
                                data-trigger="hover"
                                data-placement="bottom"
                                data-content="Add Insurance Company"
                                onClick={companyAddHandler}>
                                <i className="la la-plus" />
                                New
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className={`${props.policyDetails.ExtraFields === 'MotorPolicy' && (policyDetails && policyDetails.ProductID && (policyDetails.ProductID != 1 && policyDetails.ProductID != 3 && policyDetails.ProductID != 20 && policyDetails.ProductID != 2 && policyDetails.ProductID != 4 && policyDetails.ProductID != 21)) ? 'col-md-3' : 'col-md-4'} margin-b0`}>
                            <div className="form-group">
                                <label htmlFor="policyNo">Policy No.</label>
                                <input
                                    id="policyNo"
                                    value={policyDetails.PolicyNo || ''}
                                    name="policyNo"
                                    className="form-control margin-b10"
                                    type="text"
                                    onChange={(event) => updatePolicyDetails('PolicyNo', event.target.value)}               
                                />                     
                            </div>
                        </div>
                        <div className={`${props.policyDetails.ExtraFields === 'MotorPolicy' && (policyDetails && policyDetails.ProductID && (policyDetails.ProductID == 1 || policyDetails.ProductID == 3 || policyDetails.ProductID == 20)) ? 'col-md-2' : 'col-md-4'} margin-b0`}>
                            <div className="form-group">
                                <label htmlFor="sumInsured">Sum Insured</label>
                                <input
                                    id="sumInsured"
                                    value={policyDetails.SumInsured || ''}
                                    name="sumInsured"
                                    className="form-control margin-b10"
                                    type="number"
                                    onChange={(event) => updatePolicyDetails('SumInsured', event.target.value)}                                                   
                                />
                            </div>
                        </div>
                        {props.policyDetails.ExtraFields != 'MotorPolicy' && (
                          <div className="col-md-4 margin-b0">
                            <div className="form-group">
                              <label htmlFor="premium">Premium <span style={{'fontsize': '8px'}} >(Excluding GST)</span></label>
                              <input
                                id="premium"
                                value={policyDetails.Premium || ''}
                                name="premium"
                                className="form-control margin-b10"
                                type="number"
                                onChange={(event) => updatePolicyDetails('Premium', event.target.value)}
                              />
                            </div>
                          </div>
                        )}
                        {props.policyDetails.ExtraFields === 'MotorPolicy' && (policyDetails && policyDetails.ProductID && (policyDetails.ProductID == 2 || policyDetails.ProductID == 4 || policyDetails.ProductID == 21)) && (
                          <div className="col-md-4 margin-b0">
                            <div className="form-group">
                              <label htmlFor="premium">TP Premium <span style={{'fontsize': '8px'}} >(Excluding GST)</span></label>
                              <input
                                id="premium"
                                value={policyDetails.Premium || ''}
                                name="premium"
                                className="form-control margin-b10"
                                type="number"
                                onChange={(event) => updatePolicyDetails('Premium', event.target.value)}
                              />
                            </div>
                          </div>
                        )}
                        {props.policyDetails.ExtraFields === 'MotorPolicy' && (policyDetails && policyDetails.ProductID && (policyDetails.ProductID != 2 && policyDetails.ProductID != 4 && policyDetails.ProductID != 21)) && (
                          <>
                              <div className={`${(policyDetails && policyDetails.ProductID && (policyDetails.ProductID == 1 || policyDetails.ProductID == 3 || policyDetails.ProductID == 20)) ? 'col-md-2 ' : 'col-md-3'} margin-b0`}>
                                  <div className="form-group">
                                      <label htmlFor="premium">OD Premium <span style={{'fontSize': '11px'}} ></span></label>
                                      <input
                                          id="premium"
                                          value={policyDetails.Premium || ''}
                                          name="premium"
                                          className="form-control margin-b10"
                                          type="number"
                                          onChange={(event) => updatePolicyDetails('Premium', event.target.value)}
                                      />
                                  </div>
                              </div>
                              <div className="col-md-2 margin-b0">
                                  <div className="form-group">
                                      <label htmlFor="addOnPremium">Add On Premium <span style={{'fontSize': '11px'}} ></span></label>
                                      <input
                                          id="addOnPremium"
                                          // value={policyDetails.AddOnPremium || ''}
                                          value={policyDetails.AddOnPremium || policyDetails.AddOnPremium === 0 ? policyDetails.AddOnPremium : ''}
                                          name="addOnPremium"
                                          className="form-control margin-b10"
                                          type="number"
                                          onChange={(event) => updatePolicyDetails('AddOnPremium', event.target.value)}
                                      />
                                  </div>
                              </div>
                              {(policyDetails && policyDetails.ProductID && (policyDetails.ProductID == 1 || policyDetails.ProductID == 3 || policyDetails.ProductID == 20) && (
                                  <div className="col-md-2 margin-b0">
                                      <div className="form-group">
                                          <label htmlFor="tpPremium">TP Premium <span style={{'fontSize': '11px'}} ></span></label>
                                          <input
                                              id="tpPremium"
                                              // value={policyDetails.TPPremium || ''}
                                              value={policyDetails.TPPremium || policyDetails.TPPremium === 0 ? policyDetails.TPPremium : ''}
                                              name="tpPremium"
                                              className="form-control margin-b10"
                                              type="number"
                                              onChange={(event) => updatePolicyDetails('TPPremium', event.target.value)}
                                          />
                                      </div>
                                  </div>
                              ))}
                          </>
                        )}
                      </div>
                      <div className="row">
                        <div className={`${showRenewalBtn ? 'col-md-3' : 'col-md-4'} margin-b0`}>
                            <div className="form-group">
                                <label htmlFor="fromDate">Start Date</label>
                                <input
                                    id="fromDate"
                                    value={policyDetails.FromDate || ''}
                                    name="fromDate"
                                    className="form-control margin-b10"
                                    type="date"
                                    onChange={(event) => updatePolicyDetails('FromDate', event.target.value)}
                                />
                            </div>
                        </div>
                        <div className={`${showRenewalBtn ? 'col-md-3' : 'col-md-4'} margin-b0`}>
                            <div className="form-group">
                                <label htmlFor="toDate">End Date</label>
                                <input
                                    id="toDate"
                                    value={policyDetails.ToDate || ''}
                                    name="toDate"
                                    className="form-control margin-b10"
                                    type="date"
                                    onChange={(event) => updatePolicyDetails('ToDate', event.target.value)}
                                />
                            </div>
                        </div>
                        <div className={`${showRenewalBtn ? 'col-md-3' : 'col-md-4'} margin-b0`}>
                          <div className="form-group">
                              <label htmlFor="issueDate">Issued On</label>
                              <input
                                  id="issueDate"
                                  value={policyDetails.IssueDate || ''}
                                  name="issueDate"
                                  className="form-control margin-b10"
                                  type="date"
                                  onChange={(event) => updatePolicyDetails('IssueDate', event.target.value)}                                            
                              />
                          </div>
                        </div>
                        {showRenewalBtn && (
                          <div className="col-md-3 margin-b0">
                            <div className="form-group">
                              <label htmlFor="issueDate">Send Renewal Reminder</label>
                              <span className="kt-switch kt-switch--icon">
                                <label>  
                                  <input 
                                    id="chkActive" 
                                    name="chkActive" 
                                    type="checkbox"
                                    defaultChecked={policyDetails.SendReminder && policyDetails.SendReminder == 'N' ? '' : 'checked'}
                                    onChange={(event) => updatePolicyDetails('SendReminder', event.target.checked ? 'Y' : 'N')} 
                                  />
                                  <span></span> 
                                </label>
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                      {props.policyDetails.ExtraFields === 'MotorPolicy' && (
                        <>
                          <div className="row">
                            <div className="col-md-3">
                              <div className="form-group">
                                  <label htmlFor="registrationNo">Registration No.</label>
                                  <input
                                      id="registrationNo"
                                      value={policyDetails.RegistrationNo || ''}
                                      name="registrationNo"
                                      className="form-control margin-b10"
                                      type="text"
                                      onChange={(event) => updatePolicyDetails('RegistrationNo', event.target.value)}               
                                  />                     
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                  <label htmlFor="make">Make</label>
                                  <input
                                      id="make"
                                      value={policyDetails.Make || ''}
                                      name="make"
                                      className="form-control margin-b10"
                                      type="text"
                                      readOnly={policyDetails.MakeEditable}
                                      onChange={(event) => !policyDetails.MakeEditable && updatePolicyDetails('Make', event.target.value)}   
                                  />                     
                              </div>
                            </div>
                            <div className={`${(policyDetails && policyDetails.ProductID && (policyDetails.ProductID != 2 && policyDetails.ProductID != 4 && policyDetails.ProductID != 21)) ? 'col-md-2' : 'col-md-3'}`}>
                              <div className="form-group">
                                  <label htmlFor="model">Model</label>
                                  <input
                                      id="model"
                                      value={policyDetails.Model || ''}
                                      name="model"
                                      className="form-control margin-b10"
                                      type="text"
                                      readOnly={policyDetails.ModelEditable}
                                      onChange={(event) => !policyDetails.ModelEditable && updatePolicyDetails('Model', event.target.value)}  
                                  />                     
                              </div>
                            </div>
                            <div className={`${(policyDetails && policyDetails.ProductID && (policyDetails.ProductID != 2 && policyDetails.ProductID != 4 && policyDetails.ProductID != 21)) ? 'col-md-2' : 'col-md-3'}`}>
                              <div className="form-group">
                                <label htmlFor="ddlType">Type</label>
                                <select
                                    className="form-control kt-selectpicker"
                                    id="ddlType"
                                    value={policyDetails.Type || 'Petrol'}
                                    readOnly={policyDetails.TypeEditable}
                                    disabled={policyDetails.TypeEditable}
                                    onChange={(event) => !policyDetails.TypeEditable && updatePolicyDetails('Type', event.target.value)}>                                            
                                    <option value="Petrol">Petrol</option>
                                    <option value="Diesel">Diesel</option>
                                    <option value="Electric">Electric</option>
                                </select>
                              </div>
                            </div>
                            {(policyDetails && policyDetails.ProductID && (policyDetails.ProductID != 2 && policyDetails.ProductID != 4 && policyDetails.ProductID != 21)) && (
                              <div className='col-md-2'>
                                <div className="form-group">
                                  <label htmlFor="ncb">NCB</label>
                                  <div className='input-group'>
                                    <input
                                      name="ncb"
                                      id="ncb"
                                      value={policyDetails.NCB}
                                      max='100'
                                      className="form-control"
                                      type="number"
                                      onChange={(event) => updatePolicyDetails('NCB', event.target.value)}                                                   
                                    />
                                    <div className="input-group-append">
                                      <span className="input-group-text">%</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="EngineNo">Engine No.</label>
                                <input
                                    id="engineNo"
                                    value={policyDetails.EngineNo || ''}
                                    name="engineNo"
                                    readOnly={policyDetails.EngineNoEditable}
                                    className="form-control margin-b10"
                                    type="text"
                                    onChange={(event) => updatePolicyDetails('EngineNo', event.target.value)}               
                                />                     
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="ChassisNo">Chassis No.</label>
                                <input
                                    id="chassisNo"
                                    value={policyDetails.ChassisNo || ''}
                                    name="chassisNo"
                                    readOnly={policyDetails.ChassisNoEditable}
                                    className="form-control margin-b10"
                                    type="text"
                                    onChange={(event) => updatePolicyDetails('ChassisNo', event.target.value)}               
                                />                     
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      {props.policyDetails.ExtraFields === 'MarinePolicy' && (
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="ddlDeclarationBasis">Declaration Basis</label>
                              <select
                                  className="form-control kt-selectpicker"
                                  id="ddlDeclarationBasis"
                                  onChange={(event) => updatePolicyDetails('DeclarationBasis', event.target.value)}>                                                 
                                  <option value="Monthly">Monthly</option>
                                  <option value="Certificate Issuance">Certificate Issuance</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="perBottomLimit">Per Bottom Limit</label>
                              <input
                                  id="perBottomLimit"
                                  value={policyDetails.PerBottomLimit || ''}
                                  name="perBottomLimit"
                                  className="form-control margin-b10"
                                  type="number"
                                  onChange={(event) => updatePolicyDetails('PerBottomLimit', event.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                              <div className="form-group">
                                  <label htmlFor="perBottomLimit">Per Sending Limit</label>
                                  <input
                                      id="perSendingLimit"
                                      value={policyDetails.PerSendingLimit || ''}
                                      name="perSendingLimit"
                                      className="form-control margin-b10"
                                      type="number"
                                      onChange={(event) => updatePolicyDetails('PerSendingLimit', event.target.value)}
                                  />
                              </div>
                          </div>
                        </div>
                      )}
                      <div className="row">
                        <div className="col-md-12 margin-b20">
                          <CustomEditor
                              placeholder="Additional Details"
                              addQuickText={setAddQuickText}
                              fillQuickText={fillQuickText}
                              setFillQuickText={setFillQuickText}
                              setData={setEditorData}
                          />     
                        </div>
                      </div> 
                      {documents && documents.length > 0 && (
                        <div className="row">
                          <div className="col-12">
                              <div className="kt-notification margin-b30">
                                  {
                                    documents.map((d, i) =>
                                      <a key={i} className="kt-notification__item">
                                        <div className="kt-notification__item-icon margin-r10" onClick={() => openDocumentHandler(i)}>
                                          <img height="30px" width="30px" src={helper.getFileLogo(d.DocumentFile.name)} />
                                        </div>
                                        <div className="kt-notification__item-details" onClick={() => openDocumentHandler(i)}>
                                            <div className="kt-notification__item-title kt-font-bold">
                                                {d.DocumentName}
                                            </div>
                                        </div>
                                        <button 
                                            type="button"                    
                                            onClick={() => removeDocumentHandler(i)}
                                            className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10">
                                            <i className="flaticon2-rubbish-bin-delete-button"></i>
                                        </button>
                                      </a>
                                    )
                                  }
                              </div>
                          </div>
                        </div>
                      )}
                    </React.Fragment>
                  ) : (
                    <Dropzone fileHandler={fileHandler} prompt="Drop policy file here or click to select"/>
                  )}
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary margin-0 margin-r5"
                  data-dismiss="modal"
                  disabled={btnLoading}>    
                  Close
                </button>
                {file && (
                  <button
                    onClick={() => setAddDocument(true)}
                    type="button"
                    className="btn btn-label-brand margin-0 margin-r5"
                    disabled={btnLoading} >                 
                    <i className="flaticon-doc"></i> 
                    Attach Documents
                  </button>
                )}
                <button
                  onClick={renewPolicyHandler}
                  type="button"
                  className={`btn btn-brand margin-0 ${
                  btnLoading
                      ? 'kt-spinner kt-spinner--sm kt-spinner--light'
                      : ''
                  }`}
                  disabled={btnLoading}> 
                  Renew
                </button>
              </div>
            </div>
          </div>
        </div>
  
        {file && (
          <React.Fragment>
            <AddCompany
                type="Company"
                addInsuranceCompanyId={setAddedCompanyId}
                from="#renew-policy-modal"
            />
            {addDocument && (
              <AddDocument
                setDocumentHandler={setDocumentHandler}
                addDocument={setAddDocument}
                from="#renew-policy-modal"  
              />
            )}                    
          </React.Fragment>
        )}
  
        {emailPolicyId && (
          <EmailPolicy
            from={props.from == 'subAgentPolicyStats' && 'subAgentPolicyStats'}
            action="a"
            subAgentPolicyDetail={emailPolicy}
            groupId={props.policyDetails.ClientGroupID}
            policyId={emailPolicyId}
            refetchPolicies={refetchHandler}
          />
        )}
  
        {addQuickText && (
          <AddQuickText
              fillQuickText={setFillQuickText}
              addQuickText={setAddQuickText}
              from="#renew-policy-modal"
          />
        )} 
  
  
      </React.Fragment>  
    );
  
};

export default RenewPolicy;